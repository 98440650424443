<template>
	<el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="close" :close-on-click-modal='false'>
		<el-form :model="formInline" class="demo-form-inline" label-position="right" label-width="100px">
			<el-form-item label="类型">
				<div>{{formInline.type}}</div>
			</el-form-item>
			<el-form-item label="标题">
				<el-input v-model="formInline.title" placeholder="formInline.title"></el-input>
			</el-form-item>
			<el-form-item label="每次获得">
				<el-input v-model="formInline.each" placeholder="formInline.each"></el-input>
			</el-form-item>
			<el-form-item label="每天封顶">
				<el-input v-model="formInline.max" placeholder="formInline.max"></el-input>
			</el-form-item>
			<el-form-item label="副标题" v-if="formInline.subtitle">
				<el-input v-model="formInline.subtitle" placeholder="formInline.subtitle"></el-input>
			</el-form-item>
			<el-form-item label="行动按钮描述" v-if="formInline.btntxt">
				<el-input v-model="formInline.btntxt" placeholder="审批人"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="button()" style="margin-right: 10px">取 消</el-button>
			<el-popconfirm @confirm='button("confirm")'  confirm-button-text='是的' cancel-button-text='不用了' icon="el-icon-info" icon-color="red" title="确定要修改吗？">
				<el-button slot="reference" type="primary">确 定</el-button>
				<!-- <el-button>删除</el-button> -->
			</el-popconfirm>
			<!-- <el-button type="primary" @click="button('confirm')">确 定</el-button> -->
		</span>
	</el-dialog>
</template>

<script>
	export default {
		name: 'setting',
		props: {
			formInline: {
				type: Object,
				default:()=> {
					return {}
				}
			},
			dialogVisible: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {}
		},
		methods: {
			close() {
				this.$emit('close')
			},
			button(type) {
				this.close()
				if (type == 'confirm') this.$emit('confirm', this.formInline)
			}
		}
	}
</script>

<style>
</style>
